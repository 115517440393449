.view-grid {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 1100px) {
        display: grid;

        grid-template-areas: "content" "images";

        .view-grid-images {
            grid-area: images;
        }

        .view-grid-content {
            grid-area: content;
        }
    }
}
