@keyframes fade-in-animation {
    from {
        opacity: 0;

        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        
        transform: translateY(0%);
    }
}

@keyframes rotate-top-to-bottom {
    from {
        opacity: 0;
        transform: rotateX(90deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.rotate-top-to-bottom {
    transform-origin: top;

    animation: rotate-top-to-bottom 1s ease forwards;
}

@keyframes rotate-bottom-to-top {
    from {
        opacity: 0;
        transform: rotateX(-90deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.rotate-bottom-to-top {
    transform-origin: bottom;

    animation: rotate-bottom-to-top 1s ease forwards;
}

@keyframes fade-and-blur-in {
    from {
        opacity: 0;
        filter: blur(1em);
    }

    to {
        opacity: 1;
        filter: blur(0);
    }
}

.fade-and-blur-in {
    animation: fade-and-blur-in 1s ease forwards;
}

.developer-contact {
    display: grid;

    grid-template-columns: auto 1fr;
    grid-template-areas: "image name"
                            "image links";
}

@media only screen and (max-width: 1100px) {
    .developer-contact {
        row-gap: 1em;

        grid-template-areas: "image name"
                                "links links";
    }

    .developer-contact-links {
        justify-content: space-between;
    }

    .footer {
        flex-direction: column;
    }

    .footer-links {
        justify-content: space-around !important;
    }

    .scroller-container {
        flex-direction: column !important;
    }
    
    .scroller-intro {
        position: relative !important;
    }
    
    .scroller-content {
        flex-direction: column !important;
    }

    .scroller-content > div {
        width: 100% !important;

        font-size: 3em !important;
    }

    .scroller-content .animatable-logo img,
    .scroller-content .animatable-logo svg {
        width: 80vw !important;
    }

    .view-grid {
        flex-direction: column !important;
    }

    .view-grid-texts {
        flex-direction: column !important;
    }

    .view-grid-content {
        width: 100% !important;
    }
}

html, body, #root {
    min-height: 100vh;

    background: #171A23;
    color: #FFF;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

h3 {
    font-size: 2em;
}

a {
    font-family: "Roboto", sans-serif;
    font-weight: 400;

    color: #FFF;

    text-decoration: none;
}

li {
    line-height: 1.5em;
}

a:hover {
    text-decoration: underline;
}

@keyframes slide-left-to-right {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-top-to-bottom {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-right-to-left {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-bottom-to-top {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-wearable {
    animation-play-state: paused;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.animated-wearable-column-1 {
    animation-name: slide-left-to-right;
}

.animated-wearable-column-2:nth-child(1) {
    animation-name: slide-top-to-bottom;
}

.animated-wearable-column-2:nth-child(2) {
    animation-name: fade-in-animation;
}

.animated-wearable-column-2:nth-child(3) {
    animation-name: slide-bottom-to-top;
}

.animated-wearable-column-3 {
    animation-name: slide-right-to-left;
}


.animated-wearable-title {
    animation-name: rotate-top-to-bottom;
    animation-play-state: paused;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    transform-origin: top;
}

.animated-wearable-content {
    animation-name: fade-in-animation;
    animation-play-state: paused;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    transform-origin: top;
}
