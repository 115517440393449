.screenshot-image {
    border-style: solid;
    border-width: 1vw;
    border-color: rgba(0, 0, 0, .25);

    border-radius: 1.5vw;

    background: #171A23;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    .screenshot-image-content {
        opacity: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
    }
}
