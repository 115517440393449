.screenshot-collection {
    display: flex;
    flex-direction: column;

    flex: 1;

    gap: 1em;

    position: relative;

    height: max-content;
}
